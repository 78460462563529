import LayoutItem from "../../layoutBuilder/LayoutItem"
import KPICards from "../../components/Charts/KPICards"
import VisxChartWrapper from "../../components/ChartsVisX/shared/VisxChartWrapper"
import { getThisLastDateRange } from "../../util/util"
import { ACTIVITY_TOTAL_ENDPOINT, LATEST_API_VERSION, EMISSIONS_OVERVIEW_ENDPOINT } from "../../util/Constants"
import { Box } from "@mui/system"
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive"
import HotelIcon from "@mui/icons-material/Hotel"
import { transformArrayOfObjectsForKPICard, transformArrayOfArraysForKPICard } from "../../lib/KPICardHelper"
import { Typography } from "@mui/material"
import moment from "moment"
import styles from "./index.module.scss"
import { setQueryParameters } from "../sub-components/SetQueryParameters"

export default function DashboardKPICards({ filterDataValue }) {
	const { partners } = setQueryParameters()
	const { partnerCodes } = setQueryParameters("POST")

	const size = 12 / 5
	const dateRangeCalc = getThisLastDateRange(filterDataValue)

	const queryDataForActivityTotals = [
		{
			method: "get",
			url: ACTIVITY_TOTAL_ENDPOINT,
			params: { "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.this.dateFrom, dateTo: dateRangeCalc.this.dateTo, partners },
		},
		{
			method: "get",
			url: ACTIVITY_TOTAL_ENDPOINT,
			params: { "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.last.dateFrom, dateTo: dateRangeCalc.last.dateTo, partners },
		},
	]

	let transformedSelectionString = (filterDataValue.selectionString ? `vs. ${filterDataValue.selectionString}` : `vs. prev ${filterDataValue.daysBetween}d`).toLowerCase()
	for (const obj of [
		{ old: "this", new: "prev" },
		{ old: "last", new: "prev" },
		{ old: " months", new: "m" },
		{ old: "quarter", new: "qtr" },
		{ old: "calendar year", new: "cal yr" },
		{ old: "financial year", new: "FY" },
	]) {
		transformedSelectionString = transformedSelectionString.replace(obj.old, obj.new)
	}

	const trendUnit = transformedSelectionString

	const tooltipContent = (
		<>
			<Typography className="txt-secondary font-size-14 m-b-4" variant="h5" component="p">
				You are comparing:
			</Typography>
			<Typography className="txt-secondary" variant="p" component="p" style={{ lineHeight: 1.7, fontSize: "0.75rem" }}>
				Date range: <br />
				<b>
					{moment(dateRangeCalc.this.dateFrom).format("DD MMM YY")} - {moment(dateRangeCalc.this.dateTo).format("DD MMM YY")}
				</b>
				<br />
				With dates: <br />
				<b>
					{moment(dateRangeCalc.last.dateFrom).format("DD MMM YY")} - {moment(dateRangeCalc.last.dateTo).format("DD MMM YY")}
				</b>
			</Typography>
		</>
	)

	const KPICardConfig = [
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Emissions (tCO2e)",
			icon: null,
			queryData: [
				{
					url: EMISSIONS_OVERVIEW_ENDPOINT,
					method: "get",
					params: { partners, "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.this.dateFrom, dateTo: dateRangeCalc.this.dateTo },
				},
				{
					url: EMISSIONS_OVERVIEW_ENDPOINT,
					method: "get",
					params: { partners, "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.last.dateFrom, dateTo: dateRangeCalc.last.dateTo },
				},
			],
			dataTransformFunction: (data) => transformArrayOfArraysForKPICard(data, ["air", "road", "sea", "rail", "accommodation"], "emissions.total"),
			tooltipContent,
		},
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Flights",
			icon: <AirplanemodeActiveIcon className={`${styles["kpi-card-icon"]} ${styles.transform45}`} />,
			queryData: queryDataForActivityTotals,
			dataTransformFunction: (data) => transformArrayOfObjectsForKPICard(data, ["air.passengerFlights"]),
			tooltipContent,
		},
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Stays (room nights)",
			icon: <HotelIcon className={styles["kpi-card-icon"]} />,
			queryData: queryDataForActivityTotals,
			dataTransformFunction: (data) => transformArrayOfObjectsForKPICard(data, ["accommodation.roomNights"]),
			tooltipContent,
		},
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Cars (rental days)",
			icon: <DirectionsCarIcon className={styles["kpi-card-icon"]} />,
			queryData: queryDataForActivityTotals,
			dataTransformFunction: (data) => transformArrayOfObjectsForKPICard(data, ["road.totalRentalDays"]),
			tooltipContent,
		},
	]

	return (
		<>
			{KPICardConfig.map((item, i) => {
				const { unit, title, trendUnit, icon, trendSymbol } = item

				return (
					<LayoutItem key={item.title} xl={size} lg={size} md={size} sm={size} xs={size} innerStyle={{ height: "100%" }}>
						<Box minHeight="50px">
							<VisxChartWrapper queryData={item.queryData} dataTransformFunction={item.dataTransformFunction}>
								{({ transformedData }) => (
									<>
										{transformedData && "value" in transformedData && (
											<KPICards
												value={transformedData.value}
												trend={transformedData.trend}
												unit={unit}
												title={title}
												trendUnit={trendUnit}
												icon={icon}
												trendSymbol={trendSymbol}
												tooltipContent={tooltipContent}
											/>
										)}
									</>
								)}
							</VisxChartWrapper>
						</Box>
					</LayoutItem>
				)
			})}
		</>
	)
}
