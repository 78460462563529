import { getAllNestedKeys } from "../components/Table/TableConfigurations/ExpandableTable/ExpandableTable"
import { sortByDate } from "./SortHelper"

export const transformCostCentresToCSV = (data) => {
	let allKeys = [...getAllNestedKeys(data, sortByDate), "total"]
	let csvArray = []
	let maxCCDepth = 1

	for (const parentCostCentre of data) {
		getMaxDepth(parentCostCentre)
	}

	for (const parentCostCentre of data) {
		csvArray = [...csvArray, ...traverseCostCentre(parentCostCentre)]
	}

	function getMaxDepth(costCentre, depth = 1) {
		if (depth > maxCCDepth) maxCCDepth = depth

		for (const child of costCentre.childrenValues) {
			getMaxDepth(child, depth + 1)
		}
	}

	function fillCommas(count) {
		let str = []

		for (let i = 0; i < count; i++) {
			str.push("")
		}
		return str
	}

	function traverseCostCentre(costCentre, parentConcat = null) {
		const { name, code } = costCentre.costCentre

		let ccs = parentConcat ? [...parentConcat, { name, code }] : [{ name, code }]
		const stringifiedCCs = ccs.map((x) => `${x.name},${x.code}`)
		const ccsToFill = fillCommas((maxCCDepth - stringifiedCCs.length) * 2) // *2 because name & code

		let csvArray = [[...stringifiedCCs, ...ccsToFill, ...allKeys.map((k) => costCentre.value[k] ?? "-")].join(",")]

		for (const child of costCentre.childrenValues) {
			csvArray = [...csvArray, ...traverseCostCentre(child, ccs)]
		}
		return csvArray
	}

	let headerRow = [...allKeys]
	for (let i = maxCCDepth; i > 0; i--) {
		headerRow = [`Cost Centre Name (Level ${i})`, `Cost Centre Code (Level ${i})`, ...headerRow]
	}

	return [headerRow.join(","), ...csvArray].join("\n")
}

export const transformEmissionsLogToCSV = (data) => {
	const logData = data.data.logs
	let modeObjects = {}
	let modeColumns = {}
	let modes = []

	for (const row of logData) {
		const mode = row.mode

		// Build list of unique modes
		if (!modes.includes(mode)) modes.push(mode)

		// If mode key does not exist in mode object
		if (!modeObjects[mode]) {
			modeObjects[mode] = []
			modeColumns[mode] = []
		}

		// Standard fields across all modes
		const commonKeys = Object.keys(logData[0]).filter((x) => x !== "metadata")

		// Fields specific to mode
		const metadataKeys = Object.keys(logData[0].metadata).filter((x) => x !== "costCentre")

		// Initialise row for csv
		let rowObj = {}

		// Top level data
		for (const key of commonKeys) {
			if (!modeColumns[mode].includes(key)) modeColumns[mode].push(key)
			rowObj[key] = row[key]
		}

		// Metadata
		for (const key of metadataKeys) {
			if (!modeColumns[mode].includes(key)) modeColumns[mode].push(key)
			rowObj[key] = row.metadata[key]
		}

		modeObjects[mode].push(rowObj)
	}

	let csvPerMode = {}
	for (const mode of modes) {
		let csvForMode = [modeColumns[mode].join(",")]

		for (const obj of modeObjects[mode]) {
			let objArray = []

			for (const column of modeColumns[mode]) {
				objArray.push(obj[column] ?? null)
			}

			csvForMode.push(objArray.join(","))
		}

		csvPerMode[mode] = csvForMode.join("\n")
	}

	return csvPerMode
}
